<template>
    <div class="page-rooter-fff">
        <nav-bar header-title="邀你送券，一起激情开练"  backColor="#ffffff" :show-back="true" :header-back="newAppBack" />
        <app-page id="box" class="page-img">
            <div class="page-h">
                <div class="left-btn">
<!--                    <img @click="toTab" class="img-1" src="https://img.chaolu.com.cn/ACT/invite-2022/rule_open.png" alt="">-->
                </div>
                <div class="gift-nav">
                    <div class="row-center-center" @click="openSelectShare(1)">立即邀请</div>
                    <div @click="openSelectShare(2)"></div>
                </div>
            </div>

            <div class="invite-record">
                <div class="title-nav row-start-end">
                    <div v-if="recordType === 1" class="row-start-center">
                        <img @click="changeRecordType(1)" src="https://img.chaolu.com.cn/ACT/invite-2023/invite_title_1.png" alt="">
                        <div class="nav-right" @click="changeRecordType(2)">奖励记录</div>
                    </div>
                   <div v-else class="row-start-center">
                       <div class="nav-left" @click="changeRecordType(1)">邀请记录</div>
                       <img @click="changeRecordType(2)" src="https://img.chaolu.com.cn/ACT/invite-2023/invite_title_2.png" alt="">
                   </div>
                </div>
                <div class="invite-list">
                    <div v-if="recordType === 1" class="invite">
                        <div v-for="(item, index) in recordList" :key="index" class="invite-item row-between-center">
                            <div class="row-start-center">
                                <img :src="item.headImg" alt="">
                                <div>
                                    <div class="name ellipsis">{{ item.nickName }}</div>
                                    <p class="time">{{ item.createTime }}</p>
                                </div>
                            </div>
                            <div class="col-center-end">
                                <div v-if="item.inviteStatus === 1">待上课</div>
                                <div v-else-if="item.inviteStatus === 2"  class="success">邀请成功</div>
                                <div v-else class="fail">已失效</div>
                                <p v-if="item.inviteStatus === 1 && item.remainingDays === 0">今日23：59邀请失效</p>
                                <p v-else-if="item.inviteStatus === 1">距邀请失效仅剩 <span>{{ item.remainingDays }}</span> 天</p>
                            </div>
                        </div>
                    </div>
                    <div v-else class="award">
                        <div v-for="(item, index) in awardList" :key="index" class="award-item row-between-center">
                            <p>{{ item.receiveTime }}</p>
                            <div>{{ item.rewardName }}</div>
                        </div>
                    </div>
                    <div v-if="(recordType === 1 && !recordList.length) || (recordType === 2 && !awardList.length)" style="padding-top: 0;min-height: 200px" class="course-list empty col-center-center">
                        <img src="https://img.chaolu.com.cn/ACT/invite-2023/empty.png" alt="">
                        <div>{{ recordType === 1? '暂无邀请数据': '暂无奖励记录' }}</div>
                    </div>
                    <div v-if="recordType === 1 && !(recordList.length < 5 && finished)" class="list-finished" @click="onLoad">{{finished?'已全部加载完毕': '点击加载更多'}}</div>
                </div>
            </div>
            <div class="rule">
                <img src="https://img.chaolu.com.cn/ACT/invite-2023/invite_rule.png" alt="">
            </div>
        </app-page>


        <!-- 分享 -->
        <common-share
            :value="showShare"
            :shareParams="shareParams"
            @close="shareSuccess"
            @share-success="shareSuccess"
            :shareItem="shareItem"
            :miniSharePoster="appTypeStr === 'mini'"
            :reload="true"
        >
            <template slot="createDom">
                <!-- 需要绘制的dom 写这 -->
                <div class="share-poster-medal">
                    <div class="user-info row-start-start">
                        <img style="width: 8.8vw; height: 8.8vw;" v-if="headImg" :src="headImg" alt="">
                        <div class="use-header-name-medal ellipsis" style="max-width: 12em">{{ nickName }}</div>
                    </div>
                    <div class="share-user-medal row-between-center">
                        <div class="row-start-start" v-if="shareCourseType === 1">
                            <img v-if="selectItem.headImg" :src="selectItem.headImg" alt="">
                            <div>
                                <div class="use-header-name-medal ellipsis">{{ selectItem.name }}</div>
                                <div class="use-header-label">
                                    <span class="ellipsis-2">{{selectItem.microIntroduction}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="course-box" v-else>
                            <div>{{ selectItem.schemeTitle }}</div>
                            <p>{{ selectItem.openDate }}开班<span>{{ venueName }}</span></p>
                            <p>¥{{ selectItem.cost }}/节<span>{{ selectItem.mainTeacherName }}</span> <span class="level">{{ selectItem.mainTeacherMicroLevelMsg }}</span></p>
                            <p>{{ selectItem.timeProfile }}</p>
                        </div>
                        <div class="poster-code-cont-medal">
                            <img v-if="userPoster" class="poster-code-img" :src="userPoster" alt="">
                        </div>
                    </div>
                    <img v-if="shareCourseType === 1" class="share-bg-medal" src="https://img.chaolu.com.cn/ACT/invite-2023/poster_coach.png" alt="">
                    <img v-else class="share-bg-medal" src="https://img.chaolu.com.cn/ACT/invite-2023/poster_mini.png" alt="">
                </div>
            </template>
        </common-share>
        <!-- 微信、小程序右上角分享提示 -->
        <van-overlay :show="isShareWx" :z-index="1500" @click="isShareWx = false">
            <div class="tip-box" @click.stop>
                <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/anniversary2021/share-mini.png" class="tip-img"/>
            </div>
        </van-overlay>
        <van-popup v-model="showSelect" :duration="0.3" position="bottom" :style="{backgroundColor: 'transparent'}">
            <div class="ap-confirm3" v-if="showSelect">
                <div class="share-close" @click="showSelect=false">
                    <img src="https://img.chaolu.com.cn/ACT/invite-2023/close_icon.png" alt="" />
                </div>
                <div class="title-nav">
                    <div class="title-text">请选择推荐教练或班级</div>
                    <div v-if="shareCourseType === 1" class="row-start-center">
                        <img @click="changeShareType(1)" src="https://img.chaolu.com.cn/ACT/invite-2023/invite_title_3.png" alt="">
                        <div class="nav-right" @click="changeShareType(2)">班级</div>
                    </div>
                    <div v-else class="row-start-center">
                        <div class="nav-left" @click="changeShareType(1)">教练</div>
                        <img @click="changeShareType(2)" src="https://img.chaolu.com.cn/ACT/invite-2023/invite_title_4.png" alt="">
                    </div>
                </div>
                <div v-if="shareCourseType === 1" class="coach-box common-box">
                    <template v-if="historicTeacher.length">
                        <p>关注教练和近3个月约过的教练</p>
                        <div class="recently coach-list">
                            <div v-for="(item, index) in historicTeacher" :key="index" @click="selectItem = item" :class="{active: item.id === selectItem.id}" class="coach-item col-start-center">
                                <img @error="onError(historicTeacher, index)" :src="item.headImg" alt="">
                                <div class="ellipsis">{{ item.name }}</div>
                            </div>
                        </div>
                    </template>
                    <div class="select-venue row-between-center">
                        <div  @click="storeShow = true" :class="{active: storeShow}">
                            <span>{{ venueName }}</span>
                            <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/arrow_bottom.png" alt="">
                        </div>
                    </div>
                    <div class="venue-coach coach-list" v-if="venueTeacher.length">
                        <div v-for="(item, index) in venueTeacher" :key="item.id" @click="selectItem = item" :class="{active: item.id === selectItem.id}" class="coach-item col-start-center">
                            <img @error="onError(venueTeacher, index)" :src="item.headImg" alt="">
                            <div class="ellipsis">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="course-list empty1 col-center-center" v-else>
                        <img src="https://img.chaolu.com.cn/ACT/invite-2023/empty1.png" alt="">
                        <div>该门店暂无小班课教练<br>
                            看看其他门店吧</div>
                    </div>
                </div>

                <div v-if="shareCourseType === 2" class="course-box common-box">
                    <div class="select-venue select-venue-course row-between-center">
                        <div  @click="storeShow = true" :class="{active: storeShow}">
                            <span>{{ venueName }}</span>
                            <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/new-people-2022/arrow_bottom.png" alt="">
                        </div>
                    </div>
                    <div class="course-list" v-if="miniList.length">
                        <div v-for="item in miniList" :key="item.clazzId" @click="selectItem = item" :class="{active: item.clazzId === selectItem.clazzId}" class="course-item row-start-start">
                            <img :src="item.headIcon" alt="">
                            <div>
                                <h3>{{ item.schemeTitle }}</h3>
                                <p class="row-start-center">{{ item.openDate }}开班</p>
                                <p class="ellipsis" style="max-width: 65.33vw">{{ item.timeProfile }}</p>
                                <div class="row-between-end">
                                    <p class="row-start-center">{{ item.mainTeacherName }} <span>{{ item.mainTeacherMicroLevelMsg }}</span></p>
                                    <div class="price row-start-end">
                                        <span class="t1">¥</span>
                                        <span class="t2">{{ item.cost }}</span>
                                        <span class="t3">/节</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="course-list empty1 col-center-center" v-else>
                        <img src="https://img.chaolu.com.cn/ACT/invite-2023/empty1.png" alt="">
                        <div>该门店暂无小班课班级<br>
                            看看其他门店吧</div>
                    </div>
                </div>
                <div class="bottom-btn">
                    <div v-if="shareCourseType === 1" @click="openShare()" class="row-center-center" :class="{gray: !selectItem.id}">推荐教练</div>
                    <div v-else @click="openShare()" :class="{gray: !selectItem.clazzId}" class="row-center-center">推荐班级</div>
                </div>
            </div>
        </van-popup>
        <cl-city-venue2 :data="storeList" :popShow.sync="storeShow" @popfun="choseStorePop" type='venue'
                        :chose-item="choseItem" :single="true"></cl-city-venue2>
<!--        <transition name="van-slide-up">-->
<!--            <div class="gift-btn-bottom" v-show="showBottomBtn">-->
<!--                <img @click="faceInvite" src="https://img.chaolu.com.cn/ACT/invite-2022/btn1.png" alt="">-->
<!--                <div @click="nowInvite">立即邀请</div>-->
<!--                <img @click="openShare" src="https://img.chaolu.com.cn/ACT/invite-2022/btn2.png" alt="">-->
<!--            </div>-->
<!--        </transition>-->
    </div>
</template>
<script>
import {newAppBack, initBack, gotoCampExclusiveDetail, defaultApp, webAppShare} from '@/lib/appMethod';
import navBar from "@/components/nav-bar/nav-bar";
import appPage from "@/common/components/appPage";
import userMixin from '@/mixin/userMixin';
import commonShare from "@/components/commonShare";
import wx from "weixin-js-sdk";
import { getFormatDate } from '@/lib/utils'
import clCityVenue2 from "@/components/cl-city-venue/index3";
export default {
    components: {
        navBar,
        appPage,
        commonShare,
        clCityVenue2
    },
    mixins: [userMixin],
    data() {
        return {
            showShare: false, // 展示海报
            finished: false, // 加载完成
            isShareWx: false,  // 显示小程序分享弹窗
            showSelect: false,  // 选择教练
            shareCourseType: 1, // 分享教练和课程类型

            shareItem: ['posterfriend', 'postermessage', 'save'],
            userPoster: '',
            headImg: '',
            nickName: '',
            shareParams: {
                title: '送你一张60元小班课优惠券，点击领取体验！',
                miniImage: 'https://img.chaolu.com.cn/ACT/invite-2023/shareMini.png',
                path: `pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/invite-2023/accept&inviteUid=${this.userId}&location=1&cityId=1`,
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
                url: 'url',
                multiple: '0.95',
                userName: 'gh_7f5b57b6a76e',
            },
            recordType: 1,
            pageNum: 1,
            pageSize: 5,

            shareType: null, // 1 立即邀请  2 海报


            storeShow: false, // 场馆选择
            choseItem: {}, //原生的选择对象
            storeList: [], //原生的选择对象
            venueId: [], //原生的选择对象
            venueName: '',

            recordList: [], // 邀请记录
            awardList: [], // 奖励记录
            historicTeacher: [], // 历史预约教练
            venueTeacher: [], //场馆教练
            miniList: [], // 小班课列表

            coachList: [],
            // 选中的教练或者课程
            selectItem: {
                labels: []
            },

            isResume: false, // 是否续转

        };
    },
    mounted() {
        document.addEventListener("visibilitychange",  () => {
            if (document.visibilityState === "visible") {
                if(this.userId){
                    this.isShareWx = false
                }
            }
        })
    },
    async created() {
        initBack();
        // 活动已经下线提示活动已结束
        this.$dialog.alert({
            message: ' <b>很抱歉，该活动已经结束！<br/>去看看超鹿其他的活动吧</b>',
            confirmButtonText: '我知道了',
            className: 'dialog',
            overlayStyle: {backgroundColor: 'rgba(0,0,0,1)'}
        }).then(() => {
            if(this.appTypeStr === 'mini'){
                wx.miniProgram.switchTab({
                    url: `/pages/home/index`
                })
            }else{
                this.appBack()
            }
        })
        return false
        await this.$getUserId();
        await this.$getCityId();
        await this.$getLocal();

        this.getCityAndVenue()
        this.getIsResume()
        // 邀请奖励和记录
        this.getAwardList()
        this.getRecodeList()

        this.queryCoachList()
        this.queryClassList()
    },
    methods: {
        newAppBack,
        openSelectShare(v){
            if (this.isResume) {
                this.$dialog.alert({
                    title: '温馨提示',
                    message: `亲爱的续转会员，为了让您享受更优质的超鹿服务，我们为您独家升级新人邀请礼包。\n1、续转会员有效期内，邀请1位好友，最高可延长13天年卡权益；\n2、原有获得且未领取的128礼包仍可领取。\n 由此给您带来的不变，我们深感歉意。如有疑问，可拨打客服电话：\n 400-700-5678`,
                    confirmButtonText: '查看新的邀请奖励',
                    getContainer: '#box',
                    className: 'no-center'
                }).then(() => {
                    defaultApp();
                    if(this.appTypeStr === 'mini'){
                        this.$router.push("/emptyPage?isUndo=1")
                    }else{
                        window.location.href = `${this.baseURL}/index/identityInvite?userId=${this.userId}`
                    }

                });
                return false;
            }
            this.shareType = v
            this.showSelect = true
        },
        async openShare() {
            if(!this.selectItem?.id && !this.selectItem?.clazzId){
                this.$toast('请选择推荐教练或班级！')
                return false
            }
            if(this.shareType === 1){
                this.nowInvite()
            }else if(this.shareType === 2){
                await this.sharePoster()
                this.showSelect = false
                this.showShare = true
            }
        },
        // 是否是续转会员
        getIsResume() {
            this.$axios.post(this.baseURLApp + '/invitation/myinvite/isidentity', { userId: this.userId }).then((res) => {
                if (res.code === '1') {
                    this.isResume = res.data.identity
                }
            })
        },
        receiveReward(item) {
            this.$axios.post(this.baseURLApp + '/invitation/myinvite/myreward/receive', {
                userId: this.userId,
                rewardId: item.id
            }).then((res) => {
                if (res.code === '1') {
                    let _index = this.dataList.findIndex(x => x.id === item.id)
                    let _receiveDate = getFormatDate( new Date(), "yyyy-MM-dd")
                    let _item = Object.assign(item, {receiveDate: _receiveDate, status: 1})
                    this.$set(this.dataList, _index, _item)
                    this.$toast('领取成功！')
                } else {
                    this.$toast(res.msg)
                }
            })
        },
        getAwardList() {
            this.$axios.post(`${this.baseURLApp}/newMicro/invitation/getRewardList`,{
                "hasWx": true,
                "token": this.token,
                "userId": this.userId,
            }).then(r => {
                this.awardList = r.data || []
            })
        },
        getRecodeList() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                overlay: true,
                loadingType: 'spinner',
            })
            this.$axios.post(`${this.baseURLApp}/newMicro/invitation/invitationRecord`,{
                "hasWx": true,
                "token": this.token,
                "userId": this.userId,
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            }).then(r => {
                let list = r.data.records || []
                this.pageNum++
                this.recordList = this.recordList.concat(list)
                if (list.length < this.pageSize) {
                    this.finished = true
                }
                this.$toast.clear()
            }).catch(r => {
                this.$toast.clear()
            })
        },
        changeRecordType(v){
            this.recordType = v
        },
        changeShareType(v){
            this.shareCourseType = v
            this.selectItem = {}
            if( v === 1 && this.historicTeacher?.length){
                this.selectItem = this.historicTeacher[0]
            }else if (v === 1 && this.venueTeacher?.length){
                this.selectItem = this.venueTeacher[0]
            }else if(v === 2 && this.miniList?.length) {
                this.selectItem = this.miniList[0]
            }

        },
        onLoad() {
            if (this.finished) {
                return false
            }
            this.getRecodeList()
        },
        shareSuccess(){
            this.showShare = false
        },
        nowInvite() {
            this.shareParams.path = `pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/invite-2023/accept&inviteUid=${this.userId}&location=1&cityId=1&teacherId=${this.selectItem.id || ''}&classId=${this.selectItem.clazzId || ''}`
            this.showSelect = false
            if(this.appTypeStr === 'mini'){
                wx.miniProgram.postMessage({
                    data: {
                        type: 'share',
                        shareImage: this.shareParams.miniImage,
                        shareTitle: this.shareParams.title,
                        shareUrl: this.shareParams.path,
                    }
                });
                this.isShareWx = true
                return false;
            }
            let params = {
                scene: 0,
                ...this.shareParams
            }
            let miniParams = JSON.parse(JSON.stringify(params))
            miniParams.image = miniParams.miniImage
            webAppShare('3', miniParams)
        },
        // 获取邀请二维码
        sharePoster() {
            return new Promise(resolve => {
                this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
                    hasWx: true,
                    userId: this.userId,
                    token: this.token,
                    width: 240,
                    height: 240,
                    webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
                    scene: JSON.stringify({
                        source: '2023invite',
                        webUrl: `${window.location.origin}/#/superdeer-activity/invite-2023/accept`,
                        cityId: 1,
                        userId: 1,
                        inviteUid: this.userId,
                        teacherId: this.selectItem.id || '',
                        classId: this.selectItem.clazzId || '',
                    })
                }).then((res) => {
                    this.userPoster = 'data:image/png;base64,' + res.data.qrCode
                    this.headImg = res.data.headImg
                    this.nickName = res.data.nickName
                    resolve()
                })
            })

        },
        //门店弹出窗口返回的数据
        choseStorePop(val, param, choseName) {
            this.storeShow = false
            this.storeTotal = choseName || this.storeTotal
            if (param) {
                let {
                    areaId,
                    venueId,
                    areaName,
                    venueName
                } = param
                this.areaId = areaId
                this.venueId = venueId || []
                this.choseItem.venueId = venueId || []
                this.choseItem.areaId = areaId
                this.areaName = areaName
                this.venueName = venueName
                console.log(param)
                this.selectItem = {}
                this.queryCoachList(true)
                this.queryClassList()
            }
        },
        // 获取城市门店
        getCityAndVenue() {
            this.$axios.post(this.baseURLApp + '/app/sys/area/lessionh5/top').then(res => {
                if (res.code == 1) {
                    this.cityAndVenue = []
                    this.cityList = res.data[0].children
                    this.initCityList = JSON.parse(JSON.stringify(res.data[0].children))
                    this.setCityAndStore()
                }
            })
        },
        setCityAndStore() {
            if (this.cityId) {
                let _province = this.cityList.find(x => {
                    return x.children.some(y => y.id === this.cityId)
                })
                this.choseItem.cityId = this.cityId
                this.choseItem.provinceId = _province.id
                this.cityList.forEach((value, index) => {
                    let _result = value.children.find(y => y.id === this.cityId)
                    if (_result) {
                        this.storeList = _result.children
                    }
                })
            }
        },
        queryCoachList(isSelect = false) {
            this.$axios.post(`${this.baseURLApp}/micro/invite/bind/teacher`, {
                cityId: this.cityId,
                venueId: this.venueId[0],
                userId: this.userId,
                lng: this.local?.lng ?? null,
                lat: this.local?.lat ?? null,
            }).then(res => {
                let historicTeacher = res.data?.historicTeacher || []
                let venueTeacher = res.data?.venueTeacher

                this.historicTeacher = historicTeacher
                this.venueTeacher = venueTeacher?.venueTeacher || []
                if(!isSelect && this.historicTeacher.length) {
                    this.selectItem = this.historicTeacher[0]
                }else if(!isSelect && this.venueTeacher.length) {
                    this.selectItem = this.venueTeacher[0]
                }
                this.choseItem.venueId = [venueTeacher?.venueId]
                this.venueId =  [venueTeacher?.venueId]
                this.venueName = venueTeacher?.venueName
            })
        },
        queryClassList() {
            this.$axios.post(`${this.baseURLApp}/micro/invite/bind/clazz`, {
                cityId: this.cityId,
                venueId: this.venueId[0],
                userId: this.userId,
                lng: this.local?.lng ?? null,
                lat: this.local?.lat ?? null,
            }).then(res => {
                this.miniList = res.data?.clazzItem || []
                this.choseItem.venueId = [res.data?.venueId]
                this.venueId =  [res.data?.venueId]
                this.venueName = res.data?.venueName

                console.log(res.data?.venueName)
            })
        },
        onError(list, index){
            list[index].headImg = 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/coach/logo.jpg'
        }
    },



};
</script>

<style scoped lang="less">
.page-rooter-fff{
    background: #FFE3CF;
    .page-h{
        background: url("https://img.chaolu.com.cn/ACT/invite-2023/invite_bg.png") no-repeat center center;
        background-size: 100% 100%;
        width: 750px;
        height: 1146px;
        position: relative;
        .left-btn{
            position: absolute;
            right: 0;
            bottom: 214px;
            img{
                width: 112px;
                height: 48px;
            }
        }
        .gift-nav{
            position: absolute;
            right: 64px;
            bottom: 80px;
            width: 614px;
            height: 96px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            font-size: 24px;
            color: #3C454E;
            &>div{
                white-space: nowrap;
                &:first-child{
                    width: 230px;
                    height: 92px;
                    border-radius: 112px 112px 112px 112px;
                    opacity: 1;
                    border: 2px solid #FCF9D8;
                    box-sizing: border-box;
                    font-family: PuHuiTi;
                    color: #FFEDDA;
                    font-size: 36px;
                }
                &:last-child{
                    width: 354px;
                    height: 104px;
                    background: url("https://img.chaolu.com.cn/ACT/invite-2023/invite_btn.png") no-repeat center center;
                    background-size: 100% 100%;
                }
            }
        }
    }
    .invite-record{
        width: 678px;
        margin: 32px auto 0;
        .title-nav{
            width: 100%;
            height: 196px;
            background: url("https://img.chaolu.com.cn/ACT/invite-2023/invite_title.png") no-repeat center center;
            background-size: 100% 100%;
            box-sizing: border-box;
            div{
                img{
                    width: 358px;
                    height: 92px;
                    display: block;
                    bottom: -2px;
                }
                div{
                    width: 320px;
                    color: #3C454E;
                    font-size: 34px;
                    font-weight: bold;
                    box-sizing: border-box;
                }

            }
            .nav-left{
                padding-left: 100px;
            }
            .nav-right{
                padding-left: 84px;
            }
        }
        .invite-list{
            padding: 40px 32px;
            background-color: white;
            border-radius: 0 0 20px 20px;
            .invite-item{
                padding: 28px;
                border-radius: 16px;
                border: 2px solid #FAE4D5;
                background: #FFFAF8;
                & + .invite-item{
                    margin-top: 24px;
                }
                img{
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    margin-right: 20px;
                }
                &>div:first-child{
                    div{
                        div{
                            font-weight: bold;
                            color: #242831;
                            font-size: 28px;
                            line-height: 28px;
                            max-width: 230px;
                        }
                        p{
                            margin-top: 28px;
                            line-height: 20px;
                            font-size: 20px;
                            color: #6C727A;
                        }
                    }
                }
                &>div:last-child{
                    div{
                        padding: 8px 16px;
                        color: #FFFFFF;
                        font-size: 24px;
                        font-weight: bold;
                        line-height: 24px;
                        border-radius: 8px;
                        background: linear-gradient(90deg, #F09245 0%, #EC6A2C 100%);
                        &.success{
                            background: none;
                            color: #FF6E00;
                            font-size: 30px;
                        }
                        &.fail{
                            opacity: 0.6;
                        }
                    }
                    p{
                        margin-top: 16px;
                        line-height: 22px;
                        font-size: 22px;
                        color: #6C727A;
                        span{
                            color: #FF6E00;
                        }
                    }
                }
            }
            .award-item{
                & + .award-item{
                    margin-top: 24px;
                }
                padding: 44px 28px;
                border-radius: 16px;
                border: 2px solid #FAE4D5;
                background: #FFFAF8;
                p{
                    line-height: 20px;
                    font-size: 20px;
                    color: #6C727A;
                }
                div{
                    padding: 8px 16px;
                    color: #FFFFFF;
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 24px;
                    border-radius: 8px;
                    background: linear-gradient(90deg, #F09245 0%, #EC6A2C 100%);
                }
            }
        }
    }
    .rule{
        padding-bottom: 100px;
        img{
            display: flex;
            width: 678px;
            margin: 40px auto 0;
        }
    }
    .activity-bg{
        width: 686px;
        height: 128px;
        margin: 0 auto 40px;
        img{
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    .title-bg{
        width: 100%;
        margin: 38px auto 32px;
        &.title-bg2{
            img{
                width: 384px;
            }
        }
        img{
            width: 312px;
            height: 66px;
            display: block;
            margin: 0 auto;
        }
    }
    .rule-box{
        width: 686px;
        background: #E4F8FF;
        border-radius: 16px;
        padding: 40px 24px 76px;
        margin: 0 auto 200px;
        box-sizing: border-box;
        font-size: 24px;
        line-height: 36px;
        color: #232831;
        white-space: pre-line;
        text-align: justify;
    }
}
.ap-confirm3{
    position: relative;
    border-radius: 20px 20px 0 0;
    background: white;
    width: 750px;
    .share-close {
        img{
            width: 44px;
            height: 44px;
        }
        position: absolute;
        top: 46px;
        right: 32px;
    }
    .title-nav{
        width: 100%;
        height: 224px;
        box-sizing: border-box;
        border-radius: 20px 20px 0 0;
        background: linear-gradient(180deg, #FCF0DD 0%, #FFECCE 100%);
        .title-text{
            font-family: PuHuiTi;
            font-size: 40px;
            line-height: 40px;
            padding: 48px 0 44px;
            text-align: center;
            width: 100%;
            color: #EC672B;
        }
        div{
            img{
                width: 394px;
                height: 92px;
                display: block;
                position: relative;
                bottom: -2px;
            }
            div{
                width: 356px;
                color: #3C454E;
                font-size: 34px;
                font-weight: bold;
                box-sizing: border-box;
            }
            .nav-left{
                padding-left: 154px;
            }
            .nav-right{
                padding-left: 134px;
            }
        }
    }
    .common-box{
        height: 60vh;
        padding: 0 32px 36px;
        box-sizing: border-box;
        overflow-y: auto;
        position: relative;
    }
    .coach-box{
        &>p{
            line-height: 24px;
            font-size: 24px;
            color: #3C454E;
            margin-bottom: 36px;
            padding-top: 36px;
        }
    }

    .coach-list{
        display: flex;
        flex-wrap: wrap;
    }
    .coach-item{
        height: 258px;
        width: 208px;
        background: #FFFAF8;
        border-radius: 20px;
        padding: 24px;
        box-sizing: border-box;
        border: 2px solid #FAE4D5;
        margin-right: 30px;
        margin-bottom: 32px;
        &.active{
            background: linear-gradient(180deg, #ED7432 0%, #F0934C 100%);
            div{
                color: white;
            }
        }
        &:nth-child(3n){
            margin-right: 0;
        }
        div{
            width: 100%;
            color: #232831;
            line-height: 30px;
            font-size: 28px;
            font-weight: bold;
            margin-top: 22px;
            text-align: center;
        }
        img{
            width: 152px;
            height: 152px;
            border-radius: 19px;
            border: 2px solid #fff;
            object-fit: cover;
        }
    }
    .select-venue{
        padding: 34px 0;
        position: sticky;
        top: 0;
        background-color: white;
        &.select-venue-course{

        }
        &>div:first-child{
            display: flex;
            align-items: center;
            color: #242831;
            font-weight: bold;
            font-size: 28px;
            img{
                width: 28px;
                height: 28px;
                margin-left: 8px;
                transition: transform 0.5s;
            }
        }
        &>div.active{
            img{
                transform: rotateZ(180deg);
            }
        }
    }
    .course-item{
        border-radius: 16px;
        background-color: #FFFAF8;
        border: 2px solid #FAE4D5;
        padding: 30px 28px;
        & + .course-item{
            margin-top: 32px;
        }
        &.active{
            background: linear-gradient(180deg, #ED7634 0%, #F0914A 100%);
            div{
                color: white;
                .price{
                    .t1, .t2, .t3{
                        color: white;
                    }
                }
            }
        }
        img{
            width: 114px;
            height: 128px;
            margin-right: 28px;
            border-radius: 8px;
            object-fit: cover;
        }
        &>div{
            flex-grow: 1;
            color: #242831;
            h3{

                font-size: 28px;
                line-height: 28px;
            }
            &>p{
                font-size: 22px;
                line-height: 22px;
                margin-top: 24px;
            }
            &>div{
                font-size: 22px;
                line-height: 22px;
                margin-top: 24px;
                p{
                    span{
                        padding: 4px 6px;
                        color: white;
                        font-size: 16px;
                        border-radius: 2px;
                        line-height: 16px;
                        background: #242831;
                        margin-left: 16px;
                    }
                }

            }
            .price .t1{
                color: #F03C18;
                line-height: 20px;
                font-size: 20px;
                font-family: BebasNeueBold;
                //position: relative;
                //top: 4px;
            }
            .price .t2{
                color: #F03C18;
                line-height: 28px;
                font-size: 36px;
                font-family: BebasNeueBold;
                margin-right: 4px;
                //position: relative;
                //top: 4px;
            }
            .price .t3{
                color: #3C454E;
                line-height: 20px;
                font-size: 20px;
            }
            .price .t4{
                color: #3C454E;
                line-height: 24px;
                font-size: 24px;
                margin-left: 24px;
            }
        }
    }


    .bottom-btn{
        padding: 28px 32px calc(28px + env(safe-area-inset-bottom));
        background: #FFFFFF;
        div{
            &.gray{
                filter: grayscale(1);
            }
            width: 686px;
            height: 96px;
            background: linear-gradient(270deg, #FA6615 0%, #F0944D 100%);
            border-radius: 8px;
            font-size: 32px;
            font-weight: bold;
            color: #FFFFFF;
        }
    }
}

















.share-poster-medal{
    position: relative;
    overflow: hidden;
    width: 654px;
    height: 1100px;
    margin: 0 auto;
    //left: 5000px;
}
.user-info{
    position: absolute;
    left: 70px;
    top: 70px;
    img{
        width: 66px;
        height:66px;
        border-radius: 50%;
    }
    div{
        margin-left: 16px;
        font-size: 22px;
        font-weight: bold;
        color: #242831;
        line-height: 22px;
    }
}
.share-user-medal{
    width: 576px;
    height: 210px;
    position: absolute;
    bottom: 40px;
    left: 36px;
    display: flex;
    padding: 0 32px;
    box-sizing: border-box;
    img{
        width: 80px;
        height: 80px;
        border-radius: 8px;
    }
    .use-header-name-medal{
        margin-left: 16px;
        font-size: 26px;
        font-weight: bold;
        color: #AA3E1E;
    }
    .poster-code-cont-medal{
        width: 150px;
        height: 150px;
        //border-radius: 50%;
        box-sizing: border-box;
        flex-shrink: 0;
        img{
            width: 100%;
            height: 100%;
            display: block;
            //border-radius: 50%;
        }
    }
    .use-header-label{
        margin-top: 14px;
        flex-wrap: wrap;
        margin-left: 16px;
        font-size: 16px;
        border-radius: 4px;
        background: #FFFAF8;
        padding: 2px 6px 6px;
        span{
            color: #761624;
        }
    }
    .course-box{
        div{
            line-height: 26px;
            font-size: 26px;
            font-weight: bold;
            color: #AA3E1E;
        }
        p{
            margin-top: 16px;
            font-size: 20px;
            font-weight: 400;
            line-height: 20px;
            color: #3C454E;
            span{
                margin-left: 20px;
            }
            .level{
                margin-left: 10px;
                font-size: 14px;
                line-height: 14px;
                font-weight: bold;
                background: #3C454E;
                color: white;
                padding: 0 6px 6px;
                border-radius: 2px;
            }
        }
    }
}
.share-bg-medal{
    width: 100%;
    height: 100%;
}
.share-poster {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 654px;
    height: 1080px;
}
.share-poster .share-header {
    overflow: hidden;
    position: absolute;
    bottom: 52px;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0 0 0 32px;
}

.share-poster .use-header-img {
    display: block;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border: 4px solid #FFFFFF;
}

.use-header-text {
    line-height: 40px;
    text-align: left;
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    padding-left: 16px;
    width: 10em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .use-header-tips{
        font-size: 20px;
        color: #FFFFFF;
        line-height: 28px;
        font-weight: normal;
        &:first-child{
            margin-top: 20px;
        }
    }
}

.share-poster .poster-code-cont {
    position: absolute;
    right: 24px;
    bottom: 24px;
    background: rgba(255,255,255,0.35);
    border-radius: 50%;
    padding: 8px;
}

.share-poster .poster-code-img {
    width: 144px;
    height: 144px;
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: 50%;
    display: block;
}
.tip-box {
    position: relative;
    .tip-img {
        position: absolute;
        top: 7px;
        right: 130px;
        width: 300px;
    }
}
/deep/ .no-center .van-dialog__message{
    text-align: left!important;
}
.empty{
    padding-top: 12vh;
    img{
        width: 184px;
        height: 120px;
    }
    div{
        color: #333333;
        font-size: 24px;
        margin-top: 32px;
    }
}
.empty1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(60vh - 150px);
    img{
        width: 250px;
        height: 250px;
    }
    div{
        text-align: center;
        color: #6C727A;
        font-size: 28px;
        line-height: 44px;
    }
}
.list-finished{
    color: #7F8287;
    line-height: 24px;
    font-size: 24px;
    text-align: center;
    padding: 32px;
}
</style>
